// Grays
$gray-base: #38393E;
$gray-darker: #404040;
$gray: #767676;
$gray-dark: #767676;
$gray-light: #ddd;
$gray-lighter: #F6F6F7;
$gray-lightest: #F1F1F1;

// Theme
$primary: #1173ba;
$primary-accent: #4E99CF;

$secondary: #328538;
$secondary-darker: #27682E;

// Should look to replace these color vars with something more agnostically named
$primary-dark-blue: #181248;
$primary-purple: #2F2E7B;
$brand-secondary: #5C61AB;

// Fonts
// Fallback stacks via https://modernfontstacks.com
$font-headings: Chocolatier, sans-serif-condensed, sans-serif;
$font-body: 'Museo Sans', sans-serif;

$link-decoration: underline;

$font-regular: 300;
$font-bold: 700;

$spacer: 2rem;

$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px );

$grid-gutter-width-base: 50px;
$grid-gutter-widths: ( xs: 50px, sm: 50px, // 30px
md: $grid-gutter-width-base, // 30px
lg: $grid-gutter-width-base, // 30px
xl: $grid-gutter-width-base // 30px
);

$container-max-widths: ( sm: 100%, lg: 1300px );

$link-color: $secondary;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;

$container-large-desktop: (1270px + $grid-gutter-width-base);

$input-color: $gray-base;
$input-color-placeholder: $gray-dark;
$input-border-color: $gray-dark;

$btn-font-weight: 700;
$btn-padding-x: 2rem;
$btn-padding-y: 0.6rem;
$btn-border-radius: 100px;

$btn-primary-border: $primary-dark-blue;

$btn-secondary-color: #fff;
$btn-secondary-bg: $brand-secondary;
$btn-secondary-border: $primary-purple;

$btn-accent1-color: #fff;
$btn-accent1-bg: $secondary;
$btn-accent1-border: $secondary;

$transition: all 0.2s ease;
