.hotel-tracker {}

.hotel-tracker--group {
    border-bottom: solid 1px $gray-light;
    padding: 48px 20px 0;

    &:last-of-type {
        border-bottom: none;
    }
}

.hotel-tracker--details {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 32px;
	@include media-breakpoint-up(lg) {
		margin-bottom: 0;
	}
}

.hotel-tracker--img-container {
	padding: 0 0 24px 0;
	@include media-breakpoint-up(md) {
		@include make-col(5);
		padding: 0 32px 32px 0;
	}
	@include media-breakpoint-up(lg) {
		@include make-col(4);
		padding: 0 32px 32px 0;
	}
}

.hotel-tracker--text {
	@include make-col(12);
	& h3 {
		margin-bottom: .5rem;
	}
	& p {
		color: $primary;
	}
	& p.hotel-tracker--location {
		display: flex;
		align-items: center;
		margin-bottom: 24px;
		& strong {
			margin-right: .5rem;
		}
	}
	& img.hotel-tracker--location-icon {
		display: inline-block;
		width: 20px;
		height: 20px;
		margin-right: 10px;
		margin-left: -5px;
	}
	@include media-breakpoint-up(md) {
		@include make-col(7);
		padding: 0 32px 0 0;
		& p.hotel-tracker--address {
			@include rem-calc(22px);
		}
		& p.hotel-tracker--location {
			@include rem-calc(18px);
			& strong {
				margin-right: .5rem;
			}
		}
		& img.hotel-tracker--location-icon {
			display: inline-block;
			width: 32px;
			height: 32px;
			margin-left: -8px;
		}
	}
	@include media-breakpoint-up(lg) {
		@include make-col(6);
		padding: 0 32px 0 0;
		& p.hotel-tracker--address {
			@include rem-calc(24px);
		}
		& p.hotel-tracker--location {
			@include rem-calc(20px);
			& strong {
				margin-right: .5rem;
			}
		}
		& img.hotel-tracker--location-icon {
			display: inline-block;
			width: 40px;
			height: 40px;
			margin-left: -8px;
		}
	}
}

.hotel-tracker--book {
	@include make-col(12);
	&-btn {
		@include rem-calc(16px);
		padding: .8rem 2rem;
		display: block;
	}
	@include media-breakpoint-up(md) {
		@include make-col(12);
		&-btn {
			@include rem-calc(20px);
			padding: 1rem 2rem;
		}
	}
	@include media-breakpoint-up(lg) {
		@include make-col(2);
		&-btn {
			@include rem-calc(24px);
			padding: 1.25rem 2rem;
		}
	}
}

.hotel-tracker--capacity-progress-bar {
	margin-bottom: 32px;
	& .progress-label {
		@include rem-calc(40px);
		font-weight: $font-bold;
		text-align: center;
		margin-bottom: 0;
	}
	& .progress-bar {
		background-color: #ea383b;
		border-radius: 32px;
	}
	& .progress {
		background-color: #9aa9d6;
		border-radius: 32px;
	}
}

.hotel-tracker--capacity {
	h4 {
		margin-bottom: 0;
	}
}

.hotel-tracker--rates {
	margin-bottom:20px;

	h4 {
		margin-bottom: 0;
	}

	p {
		font-size:1.375rem;
		font-weight:300;
		color: $primary;
	}
}

.hotel-tracker--capacity-dates {
	// display: flex;
	// flex-wrap: wrap;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-height: 100%;
	margin: 0 auto;
	width: 100%;

	@include media-breakpoint-up(sm) {
		max-height: 400px;

	}

	@include media-breakpoint-up(md) {
		max-height: 300px;
		width: 90%;
	}

	
}

// .hotel-tracker--capacity-dates>* {
// 	flex: 0 0 100%;
// }

// @include media-breakpoint-up(sm) {
// 	.hotel-tracker--capacity-dates>* {
// 		flex: 0 0 50%;
// 	}
// }

// @include media-breakpoint-up(lg) {
// 	.hotel-tracker--capacity-dates>* {
// 		flex: 0 0 33.3333%;
// 	}
// }

.hotel-tracker--capacity-dates__item {
	margin-bottom: 1.25rem;

	text-align: center;

	.form-check {
		margin-bottom: 1rem;
		padding-left: 0;

		@include media-breakpoint-up(md) {
			margin-bottom: 2rem;
		}

		.form-check-label {
			color: $primary;
			@include rem-calc(20px);
			font-weight: $font-bold;
			
		}

		.form-check-input {
			background-color: #fff;
			position: absolute;
			opacity: 0;
			&+label {
				position: relative;
				cursor: pointer;
				padding: 0;
			}
			&+label:before {
				content: '';
				margin-right: 10px;
				display: inline-block;
				vertical-align: text-top;
				width: 28px;
				height: 28px;
				background: white;
				border-radius: 4px;
				border: solid 2px #9aa9d6;
			}
			&:checked+label:before {
				background: #fff;
				border: solid 2px #9aa9d6;
				border-radius: 4px;
				color: #9aa9d6;
			}
			&:disabled+label {
				color: $primary;
				cursor: auto;
			}
			&:disabled+label:before {
				box-shadow: none;
				background: #fff;
			}
			&:checked+label:after {
				content: '';
				position: absolute;
				left: 10px;
				top: 5px;
				display: inline-block;
				transform: rotate(38deg);
				height: 20px;
				width: 10px;
				border-bottom: 4px solid #9aa9d6;
				border-right: 4px solid #9aa9d6;
			}
		}
    }

    .form-check-unavailable {

        .form-check-label {
        	color: $gray-dark !important;
			text-decoration:line-through;
        }

        .form-check-input {
            &:checked+label:before {
				background: #fff;
				border: solid 2px $gray;
				border-radius: 4px;
			}

            &:checked+label:after {
				content: '';
				position: absolute;
				left: 10px;
				top: 5px;
				display: inline-block;
				transform: rotate(38deg);
				height: 20px;
				width: 10px;
				border-bottom: 4px solid $gray;
				border-right: 4px solid $gray;
				display:none;
			}
        }
    }
}
