#header {
	background: white;
	padding: 11px 0 0;
	top: 0;
	width: 100%;
	z-index: 1000;
	opacity: 1;
	transition: all 0.3s;
	position: sticky;


	@include media-breakpoint-up(md) {
		padding: 11px 0 0;
	}

	@include media-breakpoint-up(lg) {
		padding: 15px 0 0;
	}

	&.hide {
		transform: translate(0, -100%);
		background: #fff;
		opacity: 0;
	}

	.container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.logo {
	margin-bottom: 15px;

	a {
		display: inline-block;
	}

	svg {
		height: 80px;

		@include media-breakpoint-up(md) {
			height: 100px;
		}
	}

	// img {
	// 	width: 150px;
	// 	height: auto;

	// 	@include media-breakpoint-up(md) {
	// 		min-width: 200px;
	// 		margin-bottom: 0;
	// 	}

	// 	@include media-breakpoint-up(lg) {
	// 		min-width: 200px;
	// 	}
	// }
}

.topSearchWrapper {
	display: none;
	position: relative;
	z-index: 1002;

	@include media-breakpoint-up(md) {
		display: block;
		margin: 0 0 0 auto;
		display: flex;
		align-items: center;
	}

	.ui-menu {
		position: absolute !important;
		width: 330px !important;
		top: 100% !important;
		left: 0 !important;
		background: white;
		list-style: none;
		padding-left: 0;
		padding: 10px 0;
		box-shadow: 5px 13px 24px -3px rgba(0, 0, 0, .67);
		width: 364px;
		

		li {

			& > div {
				padding: 7px 15px;
				@include rem-calc(16px);
				cursor: pointer;

				&.ui-state-active {
					background: $secondary;
					color: #fff;
					border-color: $secondary;
				}
			}

			&:hover {
				background: $secondary !important;
				color: #fff !important;
				border: none !important;
			}
		}
	}
}

.topSearch {
	display: flex;
	padding: 0 25px;
	margin: 0 0 1rem;

	@include media-breakpoint-up(md) {
		margin: 0;
		border-bottom: 1px solid $gray-light;
		padding: 0;
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.3s ease-out;
	}

	&.on {

		@include media-breakpoint-up(md) {
			opacity: 1;
			pointer-events: auto;
		}
	}

	input[type="text"] {
		background: $primary-accent;
		appearance: none;
		flex-grow: 1;
		border: none;
		line-height: 40px;
		padding: 0 11px;
		border-radius: 0;
		color: #fff;


		@include media-breakpoint-up(md) {
			flex-grow: 1;
			background: transparent;
			color: $gray-dark;
			max-width: 100%;
			height: 30px;
			line-height: 30px;
			@include rem-calc(12px);
			min-width: 200px;
		}

		@include media-breakpoint-up(xl) {
			min-width: 330px;
		}

		@include placeholder {
			color: #fff;

			@include media-breakpoint-up(md) {
				color: $gray-dark;
			}
		}
	}

	input[type="submit"] {
		width: 50px;
		appearance: none;
		border: none;
		background: #fff;
		color: $secondary;
		border-radius: 0;
		font-weight: 700;
		text-transform: uppercase;
		padding: 0;
		height: 40px;
		line-height: 40px;
		margin-bottom: 0;

		@include media-breakpoint-up(md) {
			background: transparent;
			color: $secondary;
			@include rem-calc(13px);
			width: 35px;
			height: 30px;
			line-height: 30px;
		}

		&:hover {

			@include media-breakpoint-up(md) {
				background: $secondary;
				color: #fff;
			}
		}
	}
}

.topSearchToggle {
	display: none;
	color: $primary;

	@include media-breakpoint-up(md) {
		display: block;
		@include rem-calc(24px);
		cursor: pointer;
		height: 30px;
		line-height: 30px;
		margin: 0 0 0 6px;
	}

	&.on {

		&::after {
			content: "m";
		}
	}

	&:hover,
	&:focus {
		color: $secondary;
	}

	&::after {
		content: "l";
		display: block;
		font-family: $icon-font;
	}
}

.topLinks {
	display: none;

	@include media-breakpoint-up(md) {
		display: block;
		margin: 0 0 0 15px;
		@include rem-calc(14px);
		padding: 2px 0 0;
	}

	@include media-breakpoint-up(lg) {
		margin: 0 0 0 25px;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
	}

	li {
		margin: 0;
		padding-right: 1rem;
	}

	a, a:visited {
		color: $primary;
		text-transform: uppercase;
		font-weight: 700;
	}
}

.menuToggle {

	@include media-breakpoint-up(md) {
		display: none;
	}
}

.topActions {
	margin: 0 0 0 auto;

	@include media-breakpoint-up(md) {
		margin: 0 0 0 15px;
	}

	@include media-breakpoint-up(lg) {
		margin: 0 0 0 30px;
	}

	.btn {
		@include rem-calc(12px);
		padding: 0 15px;
		height: 40px;
		line-height: 37px;

		@include media-breakpoint-up(xl) {
			padding: 0 30px;
		}
	}
}

.menuToggle {
	color: $primary;
	@include rem-calc(24px);
	width: 40px;
	text-align: center;
	height: 40px;
	line-height: 40px;
	border-radius: 40px;
	border: 2px solid $primary;
	margin: 0 0 0 11px;

	&::after {
		content: "f";
		display: block;
		font-family: $icon-font;
	}
}
