.we-are {
	margin: 0 0 50px;
	box-shadow: 0 0 9px rgba(#000, 0.2);

	@include media-breakpoint-up(md) {
		display: flex;
	}

	@include media-breakpoint-up(lg) {
		margin: 0 0 48px;
	}
}

.we-are--left {
	background-size: cover;
	padding: 0 24px;
	color: #fff;
	font-size: 1.25rem;

	@include media-breakpoint-up(md) {
		@include make-col(6);
	}

	@include media-breakpoint-up(lg) {
		padding: 0 48px;
		font-size: 1.5rem;
	}

	@include media-breakpoint-up(xl) {
		padding: 0 96px;
	}

	> div {
		background-color: rgba(17, 115, 186, .85);
		padding: 24px;
	}

	h2 {
		font-size: 2rem;
		color: #fff;
		// font-weight: normal;
		line-height: 1;
		// text-transform: uppercase;

		@include media-breakpoint-up(lg) {
			font-size: 3rem;
		}

		& a {
			color: #fff;
			font-weight: normal;
			text-decoration: none;
		}
	}

	p {
		color: #fff;
		font-weight: 700;
		@include rem-calc(16px);

		@include media-breakpoint-up(md) {
			font-size: 1.25rem;
		}

		@include media-breakpoint-up(lg) {
			font-size: 1.5rem;
		}
	}

}

.we-are--right {
	background: #fff;
	padding: 25px;
	font-weight: 900;

	@include media-breakpoint-up(md) {
		@include make-col(6);
		display: flex;
		align-items: center;
		border-right: 15px solid $secondary;
	}

	@include media-breakpoint-up(lg) {
		font-size: 1.25rem;
		padding: 25px 25px 25px 50px;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	li {

		@include media-breakpoint-up(lg) {
			margin: 0 0 24px;
			line-height: 1.5;
		}
	}

	a {
		color: $primary;
		display: block;
		position: relative;
		padding-left: 15px;
		line-height: 1.4;

		@include media-breakpoint-up(lg) {
			padding-left: 25px;
		}

		&:hover,
		&:focus {
			text-decoration: none;
			color: $secondary;
		}

		&::before {
			content: "j";
			font-family: $icon-font;
			display: inline-block;
			margin: 0;
			position: absolute;
		
			left: 0;
			

			@include media-breakpoint-up(lg) {
				margin: 0 0 24px;
				line-height: 1.5;
			}
		}
	}
}
