.abstract--sidebar {
    @include media-breakpoint-up(md) {
        @include make-col(3);
    }
}

.abstract--main {
    @include media-breakpoint-up(md) {
        @include make-col(9);
        padding: 0 0 0 35px;
    }

    @include media-breakpoint-up(lg) {
        padding: 0 0 0 95px ;
    }

    @include media-breakpoint-up(xl) {
        padding: 0 0  0 95px;
    }
}


.abstract--labels {
    border-bottom: solid 1px $gray-dark;
    color: $gray-dark;
    display: none;
    font-weight: $font-bold;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    text-transform: uppercase;
    @include rem-calc(13px);

    & p {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
        display: flex;
    }
}

/*------ abstract Cards --------*/

.abstract--card {
    box-shadow: none;
    margin-bottom: 1.5rem;

    .abstract--card-item {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        &:first-child {
            padding-top: 1.5rem;
        }
    }

    .abstract--main-card-item {
        border-bottom: solid 1px $gray-light;
    }

    .abstract--card-heading {
        text-align: center;

        h6 {
            color: $secondary;
            text-align: center;
            text-transform: uppercase;
            width: 100%;
        }
    }

    .card-item.active {
        background-color: $gray-lighter;
        border-color: $gray-lighter;
    }
}

.abstract--content {
    @include make-col(12);
    text-align: left;

    h5 {
        color: $secondary;
        margin-top: .5rem;
        margin-bottom: .5rem;
    }

    .btn {
        @include rem-calc(12px);
        padding: 0 15px;
        height: 40px;
        line-height: 37px;

        @include media-breakpoint-up(xl) {
            padding: 0 30px;
        }
    }

    @include media-breakpoint-up(md) {
        @include make-col(8);
    }

    @include media-breakpoint-up(lg) {
        @include make-col(9);
    }
}

.abstract--content-ID {
    color: $secondary-darker;
    font-family: $font-body;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: .5rem;
    @include rem-calc(14px);
}

.abstract--content-date-location {
    @include rem-calc(14px);
    color: $secondary-darker;
    font-family: $font-body;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: .5rem;
}

.abstract--content-abstractid {
    @include rem-calc(14px);
    color: $secondary-darker;
    font-family: $font-headings;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: .5rem;
}

.abstract--content-topic {
    @include rem-calc(14px);
    font-family: $font-body;
    margin-bottom: .5rem;
}

.abstract--content-heading {
    margin-bottom: 1.75rem;
}

// Note we have to sometimes wrap a <p> below in an <a> due to this content being imported and unfiltered
.abstract--content-heading a,
.abstract--content-heading a p {
    @include rem-calc(24px);
    border-bottom: 2px solid $gray;
    color: $primary;
    display: inline;
    font-family: $font-body;
    font-weight: 700;
    line-height: 1.25;
    margin-top: 0 !important;
    text-decoration: none;
    text-transform: none;

    &:hover {
        border-bottom-color: $secondary;
    }

    @include media-breakpoint-up(md) {
        @include rem-calc(28px);
    }
}



/*------ abstract Sidebar Filters --------*/

.abstract--filter-group {
    margin-bottom: 2rem;
    @include rem-calc(14px);

    &-right {
        text-align: right;
    }


    .abstract--form-label {
        color: $primary-accent;
        @include rem-calc(14px);
    }

    .abstract--form-control {
        background-color: $gray-lighter;
        border-radius: 0;
        color: $gray-dark;
        @include rem-calc(14px);
    }

    .abstract--form-clear {
        background: none;
        border: none;
        color: $primary;
        display: inline-flex;
        align-items: center;

        
        &::after {
            background-color: #f6f6f7;
            border-radius: 100%;
            content: "m";
            display: block;
            font-family: asgct-icon;
            font-weight: $font-bold;
            font-size: .8rem;
            line-height: 1.4rem;
            width: 20px;
            height: 20px;
            margin-left: 5px;
              transition: all 0.3s ease-in-out;
            &:hover {
               
            }
        }

        &:hover {
      
            &::after {
                background-color: $secondary;
                color: #fff;
                transition: all 0.3s ease-in-out;
            }
        }

        &:focus {
            outline: solid 2px $primary;
        }
    }

    input[type=text],
    input[type=text]::placeholder {
        color: $gray-dark;
        @include rem-calc(14px);
      }

 

    .abstract--form-select {
		background: transparent;
		border: 1px solid $gray-dark;
        border-radius: 0;
		color: $gray-dark;
        width: 100%;
        padding: .5rem;
        @include rem-calc(14px);
        
        &:focus {
            color: #38393E;
            background-color: #fff;
            border-color: $primary-purple;
            outline: 0;
            // box-shadow: 0 0 0 0.2rem rgb(11 16 77 / 25%);
        }


		&::after {
			width: 40px;
			border-left: 1px solid $gray-dark;
			height: 100%;
			right: 0;
			text-align: center;
            color: $gray-dark;
		}

		select {
			color: $secondary;
			font-weight: 400;
			text-transform: none;
			@include rem-calc(14px);
            

			&::-ms-expand {
				display: none;
			}
		}
	}
}

/*------ abstract Details --------*/

.abstract--details {
    color: #000;
}
.abstract--details__intro {
    // font-family: $font-body;

    & h2 {
        @include rem-calc(24px);
        margin-top: 1rem;
    }

    h4 {
        font-family: $font-body;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.25;
        padding-top: 1rem;
        text-transform: none;

        @include media-breakpoint-up(md) {
            font-size: 2rem;
        }
    }

    h4 p {
        display: inline;
        font-family: $font-body;
        font-weight: 700;
        line-height: 1.25;
    }
}

.abstract--details__byline {
    @include rem-calc(18px);
}

/*------ abstract HTML Blob --------*/
.abstract {
    overflow-x: auto !important;
    @extend p;

    & h1 {
        @extend h2;
    }

 
}
