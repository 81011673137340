section.routingBlockSection {
	text-align: center;

	.contentFeatureSection + & {
		padding-top: 0;
	}

	h2 {
		@include big-header;
	}

	.card-deck {
		margin-left: 0;
		margin-right: 0;

		@include media-breakpoint-up(md) {
			flex-wrap: wrap;
			justify-content: space-between;
		}

		@include media-breakpoint-up(xl) {
			@include make-col(10);
			@include make-col-offset(1);
		}
	}

	.card {
		border-left: 15px solid #ddd;
		min-width: 100%;
		margin-left: 0 !important;
		margin-right: 0 !important;
		margin-bottom: 16px;

		@include media-breakpoint-up(md) {
			min-width: 48.8%;
			max-width: 48.8%;
			margin-bottom: 25px;
		}
	}

	.card:nth-child(4n+1) {
		border-left-color: $primary;
	}

	.card:nth-child(4n+2) {
		border-color: $primary;
	}

	.card:nth-child(4n+3) {
		border-color: $primary;
	}

	.card:nth-child(4n+4) {
		border-color: $primary;
	}
}
