footer {
	background: $primary;
	font-weight: 400;
	padding: 25px 0;
	color: #fff;
	@include rem-calc(14px);

	@include media-breakpoint-up(md) {
		@include rem-calc(16px);
	}

	@include media-breakpoint-up(lg) {
		padding: 50px 0;
	}

	a {
		color: #fff;

		&:visited {
			color: #fff;
		}

		&:hover, &:focus {
			color: $gray;
		}
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		@include rem-calc(16px);

		@include media-breakpoint-up(md) {
			@include rem-calc(18px);
			font-weight: 700;
		}
	}
}

.footerColContainer {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	@include media-breakpoint-up(xl) {
		@include make-col(10);
		@include make-col-offset(1);
	}
}

.footerCol {
	text-align: center;
	margin: 0 0 2rem;
	width: 100%;

	@include media-breakpoint-up(md) {
		width: 24%;
		text-align: left;
		border-left: 1px solid $primary-accent;
		padding: 0 15px 0 25px;
	}

	.altStyle {
		font-weight: 700;
		@include rem-calc(24px);
	}

	&:nth-child(2),
	&:nth-child(3) {
		width: 48%;

		@include media-breakpoint-up(md) {
			width: 24%;
		}
	}
}

.footerLogo {
	width: 55%;
	height: auto;
	display: block;
	margin: 0 auto 1rem;
	max-width: 170px;

	@include media-breakpoint-up(md) {
		margin: 0 0 2rem;
		width: 100%;
	}
}

.postFooter {
	color: $primary;
	padding: 25px 0;
	text-align: center;
	@include rem-calc(14px);

	p {
		margin: 0 0 0.4rem;
	}

	a, a:visited {
		color: $primary;
		text-decoration: underline;

		&:hover {
			color: $gray;
		}
	}
}

p.bkCredit {
	color: $primary;

	@include media-breakpoint-up(md) {
		@include rem-calc(11px);
	}

	a, a:visited {
		text-decoration: none;
	}
}

ul.footSocial {
	display: flex;

	& li {
		margin-right: 10px;
	}
}
