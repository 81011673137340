.mainNav {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 100%;
    width: calc(100% - 40px);
    background: $primary;
    z-index: 1001;
    transition: left 0.2s ease-out;
    padding: 50px 0 0;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;

    @include media-breakpoint-up(md) {
        display: block;
        background: $primary;
        top: auto;
        bottom: auto;
        left: auto;
        width: 100%;
        padding: 0;
        overflow: visible;
        position: relative;
    }

    &.on {
        left: 40px;
    }

    .container {
        padding: 0;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;

        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: center;
        }
    }

    li {
        margin: 0;
        position: relative;

        &::after {
            content: "";
            display: block;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(50%, -56%);
            width: 1px;
            background: #fff;
            height: 24px;
            opacity: .5;
        }

        &:last-child {

            &::after {
                display: none;
            }
        }

        a, a:visited {
            padding: 11px 25px;
            color: #fff;
            font-family: $font-body;
            font-weight: 900;
            text-transform: uppercase;
            display: block;
            font-size: 1.2rem;

            @include media-breakpoint-up(md) {
                font-size: .9rem;
                padding: 16px 24px;
            }

            @include media-breakpoint-up(lg) {
                font-size: 1.1rem;
                padding: 24px 30px;
            }

            &:hover {
                @include media-breakpoint-up(md) {
                    color: $gray-light;
                    text-decoration: none;
                }
            }
        }

        .CMSListMenuLinkActive {
            @include media-breakpoint-up(md) {
                position: relative;
                text-decoration: none;

                &:after {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;
                    border-bottom: 12px solid #fff;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }

    li ul {
        display: none;
        background: $primary;
        position: relative;

        @include media-breakpoint-up(md) {
            position: absolute;
            left: 50%;
            top: 100%;
            width: 240px;
            background: #fff;
            border-top: 5px solid $primary;
            transform: translate(-50%, 0);
            padding: 25px;
        }

        &::before {

            @include media-breakpoint-up(md) {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, 0);
                border-top: 12px solid $primary;
                border-left: 16px solid transparent;
                border-right: 16px solid transparent;
            }
        }

        &::after {

            @include media-breakpoint-up(md) {
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                border-bottom: 10px solid $primary;
            }
        }
    }

    li li {
        border: none;

        &::after {
            display: none;
        }

        a, a:visited {
            font-size: 1rem;
            padding: 7px 25px 7px 45px;

            @include media-breakpoint-up(md) {
                color: $primary;
                display: block;
                padding: 11px 0;
                text-transform: none;
                font-size: 1rem;
            }

            &:hover {
 
                @include media-breakpoint-up(md) {
                    color: $secondary;
                    text-decoration: underline;
                }
            }
        }
    }
}

.navDropToggle {
    position: absolute;
    top: 7px;
    right: 13px;
    color: #fff;
    font-size: 1.5rem;
    height: 30px;
    width: 30px;
    line-height: 30px;
    border: 1px solid $primary-accent;
    border-radius: 50px;
    background-color: $primary;


    @include media-breakpoint-up(md) {
        display: none;
    }

    &.on {

        &::after {
            content: "k";
            display: block;
            padding: 0;
        }
    }

    &::after {
        content: "h";
        display: block;
        font-family: $icon-font;
        padding: 2px 0 0;
    }
}

.navShade {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#000, 0.8);
    z-index: 1000;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-out;

    &.on {
        opacity: 1;
        pointer-events: auto;
    }
}

.mainNavClose {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #fff;
    font-size: 2rem;
    cursor: pointer;

    @include media-breakpoint-up(md) {
        display: none;
    }

    &::after {
        content: "g";
        display: block;
        font-family: $icon-font;
    }
}
