.home-content--wrapper {
	padding: 50px 0;

	@include media-breakpoint-up(lg) {
		padding: 48px 0;
	}

	.container {
		padding: 0;
	}
}
