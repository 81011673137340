.article-row {
	background: #fff;
	border-top: 15px solid $secondary;
	text-align: center;
	padding: 25px 25px 50px;
	margin: 0 0 50px;
	position: relative;
	box-shadow: 0 0 9px rgba(#000, 0.2);

	@include media-breakpoint-up(lg) {
		margin: 0 0 150px;
		padding: 75px 25px;
	}

	h2 {
		color: $primary;
		margin: 0 0 25px;

		@include media-breakpoint-up(lg) {
			@include rem-calc(36px);
			margin: 0 0 50px;
		}
	}

	.btn {
		font-weight: 700;
		@include rem-calc(16px);
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translate(-50%, 50%);
	}
}

.article-row--box-wrapper {

	@include media-breakpoint-up(md) {
		display: flex;
		justify-content: center;
	}
}

a.article-row--box {
	display: block;
	color: $primary;
	margin: 0 0 50px;
	position: relative;

	@include media-breakpoint-up(md) {
		text-align: left;
		width: calc(33.33333% - 12px);
		margin: 0 6px;
		display: flex;
		flex-direction: column;
	}

	@include media-breakpoint-up(xl) {
		width: calc(33.33333% - 40px);
		margin: 0 20px;
	}

	@include media-breakpoint-up(xxl) {
		width: calc(33.33333% - 80px);
		margin: 0 40px;
	}

	&:last-child {

		&::after {
			content: "";
			display: none;
		}
	}

	&::after {
		content: "";
		display: none;
		position: absolute;
		right: -20px;
		top: 0;
		height: 100%;
		border-right: 1px solid $gray-light;

		@include media-breakpoint-up(xl) {
			display: block;
		}

		@include media-breakpoint-up(xxl) {
			right: -40px;
		}
	}

	h3 {
		@include rem-calc(20px);
		color: $primary;
	}

	p:hover {
		text-decoration: none;
	}

	p.more {
		font-weight: 700;
		color: $secondary;
		text-transform: uppercase;
		@include rem-calc(14px);

		@include media-breakpoint-up(md) {
			text-align: right;
			margin-top: auto;
		}

		&::after {
			content: "j";
			display: inline-block;
			font-family: $icon-font;
			vertical-align: middle;
			text-transform: none;
			margin: 0 0 0 5px;
		}
	}
}

.article-row--thumb {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	margin: 0 0 25px;

	&::after {
		content: "";
		display: block;
		padding: 0 0 60%;
	}
}

.article-row--content {

	&:hover {
		text-decoration: none;
	}
	@include media-breakpoint-up(md) {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
}
