.interiorSlideshowWrapper {
	box-shadow: 1px 1px 4px rgba(#000, 0.2);
	position: relative;
	margin: 0 0 45px;

	&::after {
		content: "";
		display: block;
		padding: 0 0 66.66665%;
	}
}

.interiorSlide {
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-color: #333;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: opacity 0.3s ease-out;

	&.on {
		opacity: 1;
	}
}

.interiorSlide-caption {
	background: rgba(#000, 0.4);
	color: #fff;
	line-height: 1.4;
	@include rem-calc(12px);
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 11px;
}

.interiorSlide-nav.dots {
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translate(-50%, 5px);
	text-align: center;
	height: 9px;

	.dot {
		display: inline-block;
		width: 9px;
		height: 9px;
		border-radius: 9px;
		background: $gray-light;
		margin: 0 3px;
		cursor: pointer;

		&.active,
		&:hover {
			background: $secondary;
		}
	}
}

.interiorSlide-action {
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	width: 32px;
	height: 32px;
	background: rgba(#000, 0.4);
	border-radius: 31px;
	cursor: pointer;
	color: #fff;

	&:hover {
		background: $secondary;
	}

	&::after {
		content: "";
		display: block;
		font-family: $icon-font;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 28px;
		line-height: 1;
	}

	&.prev {
		left: 15px;

		&::after {
			content: "i";
		}
	}

	&.next {
		right: 15px;

		&::after {
			content: "j";
		}
	}
}
