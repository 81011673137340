.dropDownWrapper {
	position: relative;
	background: $secondary;
	color: #fff;
	margin: 0 0 1rem;

	&::after {
		content: "h";
		font-family: $icon-font;
		display: block;
		position: absolute;
		top: 50%;
		right: 11px;
		transform: translate(0, -50%);
		@include rem-calc(30px);
		pointer-events: none;
	}

	select {
		width: 100%;
		appearance: none;
		color: #fff;
		border: none;
		height: 40px;
		padding: 0 45px 0 15px;
		text-transform: uppercase;
		font-weight: 700;
		@include rem-calc(13px);
		background: transparent;

		&::-ms-expand {
			display: none;
		}
	}

	option {
		color: #333;
	}
}

input[type="text"] {
	color: $secondary;

	@include placeholder {
		color: $secondary;
	}
}

input[type="submit"] {
	@extend .btn, .btn-primary;
}

.contactForm {

	input[type="text"] {
		appearance: none;
		border: none;
		border-radius: 0;
		width: 100%;
		border: 1px solid $gray-dark;
		height: 40px;
		line-height: 40px;
		padding: 0 15px;
		margin: 0;

		@include placeholder {
			color: transparent;
		}
	}

	.inputDiv {
		position: relative;
		margin: 0 0 1rem;

		span.label {
			position: absolute;
			top: 9px;
			left: 15px;
			//transform: translate(0, -50%);
			transition: transform 0.3s ease-out;
			pointer-events: none;
		}

		&.focus {

			input {
				border-color: $secondary;
			}
		}

		&.on {

			span.label {

				@include media-breakpoint-up(xl) {
					left: 0;
					transform: translate(calc(-100% - 15px), 0);
					color: $secondary;
				}
			}
		}
	}

	textarea {
		border-radius: 0;
		appearance: none;
		border: 1px solid $gray-dark;
		width: 100%;
		padding: 5px 15px;
		min-height: 100px;
		margin: 0;
	}

	.dropDownWrapper {
		background: transparent;
		border: 1px solid $gray-dark;
		color: $secondary;

		&::after {
			width: 40px;
			border-left: 1px solid $gray-dark;
			height: 100%;
			right: 0;
			text-align: center;
			color: $secondary;
		}

		select {
			color: $secondary;
			font-weight: 400;
			text-transform: none;
			@include rem-calc(16px);

			&::-ms-expand {
				display: none;
			}
		}
	}

	.EditingFormErrorLabel {
		color: red;
		font-size: 14px;
	}

	input[type="checkbox"] {
		display: none;

		& + label {
			margin: 0 15px 0 0;

			@include media-breakpoint-up(lg) {
				margin-right: 25px;
			}

			&::before {
				content: "";
				display: inline-block;
				width: 20px;
				height: 20px;
				border: 2px solid $secondary;
				border-radius: 2px;
				vertical-align: middle;
				margin: 0 5px 4px 0;
				line-height: 18px;

				@include media-breakpoint-up(lg) {
					margin-left: 15px;
				}
			}
		}

		&:checked + label {

			&::before {
				border-color: $secondary;
				background: $secondary;
				content: "p";
				font-family: $icon-font;
				color: #fff;
			}
		}
	}

	input[type="radio"] {
		display: none;

		& + label {
			margin: 0 15px 0 0;

			@include media-breakpoint-up(lg) {
				margin-right: 25px;
			}

			&::before {
				content: "r";
				display: inline-block;
				border-radius: 2px;
				vertical-align: middle;
				margin: 0 5px 2px 0;
				@include rem-calc(24px);
				font-family: $icon-font;

				@include media-breakpoint-up(lg) {
					margin-left: 15px;
				}
			}
		}

		&:checked + label {

			&::before {
				content: "q";
				color: $secondary;
			}
		}
	}

	input:focus,
	select:focus,
	textarea:focus,
	button:focus {
	    outline: none;
	}

	.multipleSelect {

	}

	.multipleSelect-validate {
		background-color: transparent;
		transition: background-color 0.2s ease-out;
		padding: 5px;
		margin: 0 0 11px;
		border: 1px solid transparent;

		&.on {
			border: 1px solid red;
			background-color: red;
			color: #fff;
		}
	}
}

.mediaForm {

	.mediaForm__row {
		display: flex;
		flex-wrap: wrap;
	}

	.mediaForm__col {
		width: 416px;
		max-width: 100%;
		margin-bottom: 1.5rem;

		&--left {
			@include media-breakpoint-up(sm) {
				width: 272px;
				max-width: 100%;
				padding-right: 1rem;
			}
		}

		&--right {
			@include media-breakpoint-up(sm) {
				width: 272px;
				max-width: 100%;
			}
		}

		&--full {
			@include media-breakpoint-up(sm) {
				width: 576px;
				max-width: 100%;
			}
		}
	}

	.EditingFormLabel {
		color: $primary-accent;
		@include rem-calc(14px);
		margin-bottom: 0.25rem;
	}

	.ErrorLabel {
		display: block;
		color: #000;
		margin-bottom: 1.5rem;
	}

	.EditingFormErrorLabel {
		color: $secondary;
		@include rem-calc(11px);
	}

	.form-control {
		background-color: $gray-lighter;
		border-radius: 0;
		border: solid 1px $gray-dark;
	}

	.FormButton {
		margin-top: 1rem;
	}
}
