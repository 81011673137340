.socialLinks {

	&.topSocial {
		display: none;

		@include media-breakpoint-up(lg) {
			display: block;
			margin: 0 0 0 15px;
		}

		a, a:visited {
			background: $primary;
			color: #fff;
			@include rem-calc(16px);
			width: 25px;
			height: 25px;
			line-height: 32px;

			&:hover {
				background: $primary-accent;
			}
		}
	}

	&.footerSocial {

		a, a:visited {
			background: #fff;
			color: $secondary;
			@include rem-calc(20px);

			@include media-breakpoint-up(md) {
				@include rem-calc(16px);
			}

			&:hover {
				background: rgba(#fff, 0.8);
			}
		}

		ul {

			@include media-breakpoint-up(md) {
				justify-content: flex-start;
			}
		}
	}

	&.aside {

		a, a:visited {
			background: $primary;
			color: #fff;
			@include rem-calc(16px);
			width: 25px;
			height: 25px;
			line-height: 32px;

			&:hover {
				background: $primary-accent;
			}
		}

		ul {
			justify-content: flex-start;
		}

		li {
			margin: 0 9px 0 0;
		}
	}

	ul {
		display: flex;
		justify-content: center;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	li {
		margin: 0 4px;
	}

	a, a:visited {
		display: block;
		width: 25px;
		height: 25px;
		line-height: 33px;
		text-align: center;
		border-radius: 5px;

		&:hover {
			text-decoration: none;
		}
	}
}
