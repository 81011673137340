.cta-row {
	background: #fff;
	border-left: 15px solid $primary-accent;
	border-right: 15px solid $primary-accent;
	padding: 25px;
	margin: 0 0 50px;
	text-align: center;
	box-shadow: 0 0 9px rgba(#000, 0.2);

	@include media-breakpoint-up(lg) {
		margin: 0 0 150px;
		padding: 100px 0;
		@include rem-calc(20px);
	}

	h2 {

		@include media-breakpoint-up(lg) {
			margin: 0 0 21px;
		}
	}

	.btn {
		@include rem-calc(16px);

		@include media-breakpoint-up(lg) {
			margin-top: 25px;
		}
	}
}

.cta-row--inner {
	color: $primary;

	@include media-breakpoint-up(lg) {
		@include make-col(10);
		@include make-col-offset(1);
	}

	@include media-breakpoint-up(xl) {
		@include make-col(8);
		@include make-col-offset(2);
	}

}
