.pagination {
	text-align: center;
	margin: 0 0 2rem;
	justify-content: center;

	a, a:visited {
		color: $secondary;
	}

	.paginationItem {
		display: inline-block;
		width: 25px;
		height: 25px;
		text-align: center;
		line-height: 25px;

		&::before {
			content: "";
			display: inline-block;
			font-family: $icon-font;
		}
	}

	.first {

		&::before {
			content: "n";
		}
	}

	.prev {

		&::before {
			content: "i";
		}
	}

	.next {

		&::before {
			content: "j";
		}
	}

	.last {

		&::before {
			content: "o";
		}
	}
}
