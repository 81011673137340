.headerShim {
	height: 62px;

	@include media-breakpoint-up(md) {
		height: 120px;
	}

	@include media-breakpoint-up(lg) {
		height: 152px;
	}

	@include media-breakpoint-up(xl) {
		height: 156px;
	}
}