.what-is {
	background: #fff;
	padding: 25px;
	box-shadow: 0 0 9px rgba(#000, 0.2);

	@include media-breakpoint-up(md) {
		display: flex;
		align-items: center;
		border-left: 15px solid $secondary;
		padding: 25px 0;
	}

	@include media-breakpoint-up(lg) {
		padding: 50px 0;
	}

	@include media-breakpoint-up(xl) {
		padding: 70px 0;
	}
}

.what-is--left {
	position: relative;
	margin: 0 0 25px;

	@include media-breakpoint-up(md) {
		@include make-col(6);
		margin: 0;
	}

	&::after {
		content: "";
		display: block;
		padding: 0 0 56%;
	}

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.what-is--right {

	@include media-breakpoint-up(md) {
		@include make-col(6);
		padding: 0 0 0 25px;
	}

	@include media-breakpoint-up(lg) {
		padding: 0 0 0 60px;
		@include rem-calc(20px);
		line-height: 1.6;
	}

	h2 {
		@include media-breakpoint-up(lg) {
			@include rem-calc(36px);
			margin: 0 0 31px;
		}
	}

	p {
		color: $primary;
	}

	.btn {
		@include rem-calc(16px);
	}
}
