.CookieConsent {
    position: fixed;
    padding: 15px;
    bottom: 0;
    left: 0;
    width: 100vw;
    z-index: 100;
    background-color: white;
    text-align: center;
    display: flex;
    border-top: 1px solid gray;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: space-between;
    }

    .ConsentText {
        text-align: center;
        display: block;

        @include media-breakpoint-up(md) {
            text-align: left;
        }
    }

    .ConsentButtons {
        display: block;
        min-width: 100%;
        padding-top: 0.8rem;

        @include media-breakpoint-up(md) {
            text-align: right;
            min-width: 450px;
            padding-right: 1.4rem;
            padding-top: 0;
        }

        input {
            margin: 0 0 1rem;
            font-size: 0.6rem;

            @include media-breakpoint-up(md) {
                margin: 0 0 0 15px;
                font-size: 1rem;
            }
        }
    }
}