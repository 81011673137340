section.homeIntroSection {
	background: url(../images/global/home-intro-bg.jpg) 50% 50% no-repeat;
	background-size: cover;
}

.homeIntro {
	background: #fff;
	box-shadow: $box-shadow;
	border-left: 15px solid $secondary;
	padding: 25px;
	color: $secondary;

	@include media-breakpoint-up(md) {
		padding: 40px 65px;
		@include rem-calc(20px);
	}

	@include media-breakpoint-up(xl) {
		@include make-col(10);
		@include make-col-offset(1);
	}

	h1 {
		@include big-header;
		color: $primary;
	}
}
