section.blog-landing--body {

	.container {

		@include media-breakpoint-up(lg) {
			display: flex;
		}
	}

	main {

		@include media-breakpoint-up(lg) {
			@include make-col(9);
			padding-right: 45px;
		}
	}

	aside {

		@include media-breakpoint-up(lg) {
			@include make-col(3);
		}

		@include media-breakpoint-up(xl) {
			@include make-col(2);
			@include make-col-offset(1);
		}

		h2 {
			@include rem-calc(26px);
			color: $brand-secondary;
		}

		ul {
			font-weight: 700;
			@include rem-calc(16px);
		}

		.show-more {
			appearance: none;
			border: none;
			background: transparent;
			color: $secondary;
			font-weight: 400;

			&::after {
				content: "+";
				display: inline-block;
				margin: 0 0 0 13px;
			}

			&:hover {
				text-decoration: underline;
			}
		}
	}

	h1 {
		color: $primary;
		margin: 0 0 45px;
	}
}

.blog-landing--title {
	margin: 30px 0;
	border-left: 15px solid $secondary;
	@include rem-calc(20px);
	color: $primary;
	padding: 4px 0 4px 15px;
	font-weight: 600;
}