.card {
	margin: 0 0 2rem;
	border: none;
	box-shadow: $box-shadow;
	border-radius: 0;
	text-align: center;

	@include media-breakpoint-up(md) {
		text-align: left;
	}

	.newsList & {

		&.hasImage {
			border: none;

			@include media-breakpoint-up(md) {
				display: flex;
				flex-wrap: nowrap;
				flex-direction: row;
			}
		}
	}
}

.card-thumb {

	&::after {
		content: "";
		display: block;
		padding: 0 0 70%;
	}

	.newsList & {

		@include media-breakpoint-up(md) {
			width: 200px;
			min-width: 200px;
		}

		@include media-breakpoint-up(xl) {
			min-width: 300px;
		}
	}
}

.card-content {

	.newsList & {

		@include media-breakpoint-up(md) {
		}
	}
}

.card-block {
	padding: 16px;

	@include media-breakpoint-up(md) {
		padding: 40px;
	}

	@include media-breakpoint-up(md) {
		padding: 40px 48px;
	}

	h4 a {
		color: $primary;
	}

	p {
		color: black;
		min-height: 75px;
	}
}

.card-meta {
	margin: 0 0 0.6rem;

	@include media-breakpoint-up(md) {
		margin: 0 0 20px;
	}

	a, a:visited {
		color: $primary;
		font-weight: 600;
		text-transform: uppercase;
		display: inline-block;
		margin: 0 5px;
		@include rem-calc(14px);

		@include media-breakpoint-up(md) {
			margin: 0 9px 0 0;
		}
	}
}

.card-footer {
	border: none;
	background: transparent;

	@include media-breakpoint-up(md) {
		text-align: right;
		@include rem-calc(14px);
		padding: 0 25px 15px;
	}

	a, a:visited {
		color: $secondary;
		text-transform: uppercase;
		font-weight: 700;

		&::after {
			content: "j";
			text-transform: none;
			display: inline-block;
			margin: 0 0 0 3px;
			font-family: $icon-font;
			vertical-align: middle;
		}
	}
}
