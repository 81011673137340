.agenda--sidebar {
    @include media-breakpoint-up(md) {
        @include make-col(3);
    }

    .content--date {
        color: $secondary;
        font-weight: 700;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }
}

.agenda--main {
    @include media-breakpoint-up(md) {
        @include make-col(9);
        padding: 0 0 0 35px;
    }

    @include media-breakpoint-up(lg) {
        padding: 0 0 0 95px ;
    }

    @include media-breakpoint-up(xl) {
        padding: 0 0  0 95px;
    }
}

.agenda--sponsor-banner {
    max-width: 100%;
    text-align: center;
    @include make-col(12);
}

.agenda--sponsor-banner-byline {
    color: $gray-dark;
    @include rem-calc(11px);
    font-weight: $font-bold;
    text-transform: uppercase;
    margin-bottom: .5rem;
}

.agenda--sponsor-banner-img__container {
    border: solid 1px $gray-light;
    margin: 0 auto;
    padding: 1rem;
    text-align: center;
}
.agenda--sponsor-banner-img {

}

.agenda--labels {
    border-bottom: solid 1px $gray-dark;
    color: $gray-dark;
    display: none;
    font-weight: $font-bold;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    text-transform: uppercase;
    @include rem-calc(13px);

    & p {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
        display: flex;
    }
}

/*------ Agenda Nav Tabs --------*/

.agenda--tabs {
    justify-content: flex-start;
    margin-bottom: 2.5rem;
    @include rem-calc(15px);


    @include media-breakpoint-up(md) {
        justify-content: flex-start;
    }

    & li.nav-item {
        margin-bottom: 0;
        text-align: center;
        width: 50%;

        @include media-breakpoint-up(sm) {
            width: 33.3%;
        }


        @include media-breakpoint-up(xl) {
            width: 20%;
        }

    }
    & a.nav-link {
        border-bottom: solid 6px transparent;
        border: none;
        font-family: $font-headings;
        font-weight: 700;
        padding: 1.1rem 1rem;
        text-decoration: underline;
        text-transform: uppercase;


    &.active,
    &:hover,
    &:focus {
        border: none;
        border-bottom: solid 6px $secondary;
        color: $secondary;
        text-decoration: none;
    }

    // @include media-breakpoint-up(md) {
    //     @include rem-calc(16px);
    // }

    @include media-breakpoint-up(lg) {
        @include rem-calc(20px);
        padding: 1.1rem 1rem;
    }


  }
    
}

/*------ Agenda Cards --------*/

.agenda--card {
    box-shadow: none;
    margin-bottom: 1.5rem;

    .agenda--main-card-item {
        background-color: $secondary-darker;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    .agenda--card-item {
        align-items: center;
        background-color: $secondary;
        // border-top: 7px solid rgba($gray, .3);
        // border: solid 2px #000;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        color: #fff;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 1rem;
        text-decoration: none;

        &:hover,
        &:focus {
            // outline: solid 2px $secondary;
            border-color: $secondary;
            text-decoration: none;
        }
    }

    .agenda--sub-card-item {
        background-color: #fff;
        border-radius: 0;
        border-top: 1px solid $secondary-darker;
        border-bottom: 0;
        color: $secondary-darker;
        padding: .8rem 1rem;

        &:last-child {
            border-bottom: solid 2px $secondary-darker;
        }
    }

    .agenda--card-heading {
        text-align: center;

        h6 {
            color: #fff;
            font-weight: $font-bold;
            text-align: center;
            text-transform: uppercase;
            width: 100%;
        }
    }

    .card-item.active {
        background-color: $gray-lighter;
        border-color: $gray-lighter;
    }

}

.agenda--content-date-location {
    // color: $secondary-darker;
    font-family: $font-body;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: .5rem;
    @include rem-calc(14px);
}


.agenda--content {
    text-align: left;
    @include make-col(12);

    h5 {
        color: inherit;
        font-size: 1.4rem;
        line-height: 1.25;
        margin-top: .5rem;
        margin-bottom: .5rem;
    }

    p {
        font-weight: $font-regular;
        @include rem-calc(18px);
    }

    p.agenda--content-date-location {
        // color: $secondary-darker;
        font-family: $font-body;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: .5rem;
        @include rem-calc(14px);
    }
    
    @include media-breakpoint-up(md) {
        @include make-col(8);
    }

    @include media-breakpoint-up(lg) {
        @include make-col(9);
    }
  
    
}

.agenda--sponsor {
    @include make-col(12);
    // margin: auto;
    padding: .5rem 0 0 0;

    & img {
        width: 100px;
    }

    @include media-breakpoint-up(md) {
        @include make-col(4);
        padding: .5rem 0 .5rem 3rem;
    }
    @include media-breakpoint-up(lg) {
        @include make-col(3);

        & img {
            width: 130px;
        }
    }


 
   


}

/*------ Agenda Sidebar Filters --------*/

.agenda--filter-group {
    margin-bottom: 2rem;
    @include rem-calc(14px);


    .agenda--form-label {
        color: $primary;
        font-weight: bold;
        @include rem-calc(14px);
    }

    .agenda--form-control {
        background-color: $gray-lighter;
        border-radius: 0;
        color: $gray-dark;
        @include rem-calc(14px);
    }

    input[type=text],
    input[type=text]::placeholder {
        color: $gray-dark;
        @include rem-calc(14px);
      }
 

    .agenda--form-select {
		background: transparent;
		border: 1px solid $gray-dark;
        border-radius: 0;
		color: $gray-dark;
        width: 100%;
        padding: .5rem;
        @include rem-calc(14px);
        
        &:focus {
            color: #38393E;
            background-color: #fff;
            border-color: $primary-purple;
            outline: 0;
            // box-shadow: 0 0 0 0.2rem rgb(11 16 77 / 25%);
        }


		&::after {
			width: 40px;
			border-left: 1px solid $gray-dark;
			height: 100%;
			right: 0;
			text-align: center;
            color: $gray-dark;
		}

		select {
			color: $secondary;
			font-weight: 400;
			text-transform: none;
			@include rem-calc(14px);
            

			&::-ms-expand {
				display: none;
			}
		}
	}
}

/*------ Agenda Details --------*/

.agenda--details {
    color: #767676;
}
.agenda--details__intro {
    margin-bottom: 2.5rem;

    & h2 {
        @include rem-calc(24px);
        margin-top: 1rem;
    }
}

.agenda--details__byline {
    @include rem-calc(18px);
}

/*---- Agenda Toggle ----*/

.agendaChild {
    padding-left: 40px;
	display: none;
}

.agendaToggle {
	@include rem-calc(14px);
    color: #fff;
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
    background-color: transparent;
    border: 0;
    padding: 0 1rem;

    &:hover {
        // color: $primary-dark-blue;
        text-decoration: underline;
    }


	&.on {
		&::after {
			content: "k";
		}
	}

	&::after {
		content: "h";
		text-transform: none;
		display: inline-block;
		margin: 0 0 0 3px;
		font-family: $icon-font;
		vertical-align: middle;
		font-weight: normal;
		@include rem-calc(26px);
	}
}

/*------ Agenda Detail Calendar Download --------*/

.agenda--details input.download_calendar,
.agenda--details input.download_calendar:hover,
.agenda--details input.download_calendar:active,
.agenda--details input.download_calendar:focus {
    background-color:transparent !important;
    appearance: none !important;
    padding: 0 15px !important;
    width: 100% !important;
    margin: 0 0 1rem !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    width: 75px !important;
    height: 75px !important;
    border: none !important;
    box-shadow: none !important;
}
