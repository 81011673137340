.table {
	@include rem-calc(14px);
	@extend .table-striped;
	box-shadow: $box-shadow;
	border-left: 15px solid $secondary;

	th {
		color: $secondary;
		text-transform: uppercase;
	}
}
