.bioBoxContainer {

}

.bioBox {
	box-shadow: $box-shadow;
	padding: 25px;
	text-align: center;
	margin: 0 0 2rem;

	@include media-breakpoint-up(sm) {
		display: flex;
		flex-wrap: nowrap;
		align-items: flex-start;
		text-align: left;
	}

	@include media-breakpoint-up(md) {
		padding: 35px 30px 20px;
		margin: 3rem 0 5rem;
	}

	h2 {
		color: $secondary;
		position: relative;
	}
}

.bioBoxThumb {
	max-width: 150px;
	margin: 0 auto 1rem;

	@include media-breakpoint-up(sm) {
		min-width: 25%;
	}

	@include media-breakpoint-up(md) {
		min-width: 155px;
	}

	&::after {
		content: "";
		display: block;
		padding: 0 0 100%;
	}
}

.bioBoxContent {

	@include media-breakpoint-up(sm) {
		padding: 0 0 0 31px;
	}

	h2 {
		@include rem-calc(22px);

		@include media-breakpoint-up(md) {
			@include rem-calc(24px);
		}
	}
}

.bioBoxFooter {

	@include media-breakpoint-up(md) {
		text-align: right;
	}

	a, a:visited {
		color: $secondary;
		text-transform: uppercase;
		font-weight: 700;
		@include rem-calc(14px);

		&::after {
			content: "j";
			text-transform: none;
			display: inline-block;
			margin: 0 0 0 3px;
			font-family: $icon-font;
			vertical-align: middle;
		}
	}
}
