.article-grid--wrapper {
	margin: 0 0 50px;

	@include media-breakpoint-up(md) {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0 0 150px;
	}
}

a.article-grid-item {
	display: block;
	@include rem-calc(14px);
	color: $gray-dark;
	margin: 0 0 35px;
	position: relative;

	@include media-breakpoint-up(sm) {
		display: flex;
		align-items: center;
		margin: 0 0 25px;
	}

	@include media-breakpoint-up(md) {
		width: calc(50% - 8px);
		margin: 0 0 16px;
		position: relative;
		padding: 75px 0 35px;
	}

	@include media-breakpoint-up(lg) {
		padding: 100px 0 35px;
	}

	&:hover,
	&:focus {
		text-decoration: none;
		box-shadow: 0 0 9px rgba(#000, 0.2);
	}

	h2 {
		@include rem-calc(24px);
		color: $secondary;
		margin: 0 0 11px;
	}
}

.article-grid-item--tags {
	margin: 0 0 11px;
	font-weight: 600;
	text-transform: uppercase;
}

.article-grid-item--content {
	position: relative;

	@include media-breakpoint-up(md) {
		background: rgba(#fff, 0.9);
		padding: 25px;
	}
}

.article-grid-item--thumb {
	display: none;

	@include media-breakpoint-up(md) {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50% 50%;
	}
}

.article-grid--author {
	color: $primary;
	font-weight: 600;
	text-transform: uppercase;
}
