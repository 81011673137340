.bodyTags {
	@include rem-calc(14px);
	padding: 25px 0 0;

	a, a:visited {
		color: $primary;
		font-weight: 700;
		text-transform: uppercase;

		&::after {
			content: "|";
			display: inline-block;
			margin: 0 7px;
		}

		&:last-child {

			&::after {
				display: none;
			}
		}
	}
}
