section.membershipSection {
	background: $primary;
	background-size: cover;
	color: #fff;
	text-align: center;
	margin-bottom: 2rem;
}

.membershipIntro {

	@include media-breakpoint-up(md) {
		display: flex;
		align-items: center;
		margin: 0 0 3rem;
	}

	@include media-breakpoint-up(xl) {
		@include make-col(10);
		@include make-col-offset(1);
	}
}

.membershipIntroLeft {
	font-weight: 700;
	@include rem-calc(30px);
	line-height: 1.2;
	margin: 0 0 0.8rem;

	@include media-breakpoint-up(md) {
		text-align: left;
		padding: 0 25px 0 0;
		margin: 0;
	}

	@include media-breakpoint-up(lg) {
		white-space: nowrap;
		padding: 0 40px 0 0;
		@include rem-calc(48px);
	}
}

.membershipIntroRight {
	@include rem-calc(18px);
	margin: 0 0 2rem;

	@include media-breakpoint-up(md) {
		margin: 0;
		text-align: left;
		padding: 0 0 0 25px;
		border-left: 3px solid $primary-accent;
	}

	@include media-breakpoint-up(lg) {
		padding: 0 0 0 40px;
		@include rem-calc(19px);
	}
}

.membershipContent {

	@include media-breakpoint-up(md) {
		display: flex;
	}

	@include media-breakpoint-up(xl) {
		@include make-col(10);
		@include make-col-offset(1);
	}

	h3 {
		color: #fff;
		margin: 0 0 2rem;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0 0 1.8rem;
	}

	li {
		font-weight: 700;
	}
}

.membershipLeft {
	text-align: center;
	margin: 0 0 3rem;

	@include media-breakpoint-up(md) {
		width: 50%;
		text-align: left;
		padding: 0 40px 0 0;
	}

	li {
		margin: 0 0 0.4rem;
	}
}

ul.membershipBenefitsList {
	text-align: left;

	li {
		display: flex;
		align-items: center;
		margin: 0 0 1.2rem;

		@include media-breakpoint-up(lg) {
			@include rem-calc(20px);
		}
	}
}

.membershipIcon {
	width: 30px;
	height: 30px;
	display: inline-block;
	vertical-align: middle;
	margin: 0 9px 0 0;

	@include media-breakpoint-up(lg) {
		height: 35px;
		width: 35px;
		margin: 0 15px 0 0;
	}
}

.membershipJournalWrapper {

	@include media-breakpoint-up(lg) {
		display: flex;
		align-items: flex-start;
	}
}

.membershipJournalLeft {
	text-align: center;
	margin: 0 0 2rem;

	a, a:visited {
		color: #fff;
		text-transform: uppercase;
		font-weight: 700;
		text-decoration: underline;
		@include rem-calc(14px);

		&::after {
			content: "j";
			text-transform: none;
			font-family: $icon-font;
			display: inline-block;
			margin: 0 0 0 5px;
			vertical-align: middle;
		}
	}
}

.membershipJournalThumb {
	border: 5px solid #fff;
	margin: 0 auto 1rem;
	display: block;
	max-width: 184px;
}

.membershipJournalRight {

	@include media-breakpoint-up(lg) {
		padding: 0 0 0 35px;
		@include rem-calc(16px);
	}
}

.membershipRight {
	text-align: center;

	@include media-breakpoint-up(md) {
		width: 50%;
		text-align: left;
	}

	@include media-breakpoint-up(lg) {
		@include rem-calc(20px);
	}

	h4 {
		color: #fff;
		font-style: normal;

		@include media-breakpoint-up(lg) {
			@include rem-calc(20px);
		}
	}
}
