section.newsSection {
	background: url(../images/global/newsSectionBg.jpg) 50% 50% no-repeat;
	background-size: cover;
	text-align: center;
}

.newsSectionIntro {
	margin: 0 0 2rem;

	@include media-breakpoint-up(md) {
		max-width: 700px;
		margin: 0 auto 3rem;
	}

	@include media-breakpoint-up(lg) {
		@include rem-calc(20px);
		margin: 0 auto 4rem;
	}
}

.newsSectionContent {

	@include media-breakpoint-up(md) {
		display: flex;
		justify-content: space-between;
		margin: 0 0 2rem;
	}

	@include media-breakpoint-up(xl) {
		@include make-col(10);
		@include make-col-offset(1);
	}
}

.newsSectionFeatureBox {
	border-left: 15px solid $primary-accent;
	box-shadow: $box-shadow;
	background: #fff;
	padding: 25px;
	margin: 0 0 2rem;

	@include media-breakpoint-up(sm) {
		display: flex;
		padding: 0;
	}

	@include media-breakpoint-up(md) {
		margin: 0;
	}

	h2 {
		color: $secondary;
		font-style: italic;
	}

	h3 {
		color: $secondary;
		@include rem-calc(20px);
	}
}

.newsSectionFeatureThumb {
	display: none;

	@include media-breakpoint-up(sm) {
		display: block;
		width: 35%;
		min-width: 35%;
	}
}

.newsSectionFeatureContent {

	@include media-breakpoint-up(sm) {
		text-align: left;
		padding: 35px 25px 15px 35px;
	}

	p.all {

		@include media-breakpoint-up(sm) {
			text-align: right;
		}

		a, a:visited {
			font-weight: 700;
			text-transform: uppercase;
			@include rem-calc(14px);
			color: $secondary;

			&::after {
				content: "j";
				text-transform: none;
				display: inline-block;
				margin: 0 0 0 3px;
				font-family: $icon-font;
				vertical-align: middle;
			}
		}
	}
}

.newsSectionFeatureDate {
	font-weight: 600;
	margin: 0 0 21px;
}

.newsSectionFeatureTwitter {
	box-shadow: $box-shadow;
	border-left: 15px solid $secondary;
	padding: 25px;
	margin: 0 0 2rem;
	background: #fff;
	max-height: 300px;
	overflow: auto;

	@include media-breakpoint-up(md) {
		min-width: 250px;
		max-height: 700px;
		margin: 0 0 0 15px;
	}

	@include media-breakpoint-up(lg) {
		margin: 0 0 0 30px;
		min-width: 350px;
		max-height: 600px;
	}

	h2 {
		color: $secondary;
	}
}

.therapy101 {
	background: #fff;
	box-shadow: $box-shadow;
	padding: 0 0 25px;

	@include media-breakpoint-up(md) {
		padding: 0;
	}

	@include media-breakpoint-up(xl) {
		@include make-col(10);
		@include make-col-offset(1);
	}

	a.btn {

		@include media-breakpoint-up(md) {
			transform: translate(0, 50%);
		}
	}
}

.therapy101Header {
	background: $secondary;
	color: #fff;
	@include rem-calc(24px);
	padding: 11px;
	margin: 0;

	@include media-breakpoint-up(md) {
		@include rem-calc(30px);
		margin: 0 0 30px;
	}

	@include media-breakpoint-up(lg) {
		@include rem-calc(36px);
		margin: 0 auto 50px;
	}
}

.therapy101BoxContainer {
	margin: 0 0 2rem;

	@include media-breakpoint-up(md) {
		display: flex;
	}
}

.therapy101Box {
	padding: 25px;

	@include media-breakpoint-up(md) {
		text-align: left;
		border-right: 1px solid $gray-light;
		padding: 0 30px;
		width: 33.33333%;
	}

	h3 {
		color: $secondary;
		@include rem-calc(20px);
	}

	p.more {

		@include media-breakpoint-up(sm) {
			text-align: right;
		}

		a, a:visited {
			font-weight: 700;
			text-transform: uppercase;
			color: $secondary;

			@include media-breakpoint-up(sm) {
				@include rem-calc(14px);
			}

			@include media-breakpoint-up(md) {
				margin-left: auto;
			}

			&::after {
				content: "j";
				text-transform: none;
				display: inline-block;
				margin: 0 0 0 3px;
				font-family: $icon-font;
				vertical-align: middle;
			}
		}
	}
}
