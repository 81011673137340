.imageCaptionDiv {
	margin: 0 0 2rem;
	
	img {
		margin: 0 0 15px;
	}

	p {
		border-left: 3px solid $secondary;
		padding: 0 0 0 15px;
		@include rem-calc(14px);
	}
}
