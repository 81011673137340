section.mainBody {
	padding-top: 64px;


	.contentFeatureSection + &,
	.filterSection + & {
		padding-top: 0;
	}

	.container {

		@include media-breakpoint-up(md) {
			display: flex;
			flex-wrap: nowrap;
		}

		&.is-full-width {

			main {

				@include media-breakpoint-up(md) {
					@include make-col(12);
					max-width: 100%;
				}

				padding: 0;
			}
		}
	}

	main {
		@include make-col(12);
		padding-bottom: 45px;
		max-width: 100%;

		@include media-breakpoint-up(md) {
			@include make-col(9);
			padding: 0 35px 0 0;
		}

		@include media-breakpoint-up(lg) {
			padding: 0 95px 0 0;
		}

		@include media-breakpoint-up(xl) {
			padding: 0 95px 0 0;
		}


		&.subheading {
			font-size: 16px;
		}

		// p {
		// 	color: black;
		// }

		.btn { 
			margin-bottom: .8rem; 
			}
	}

	&.list {

		main {

			@include media-breakpoint-up(lg) {
				padding: 0 35px 0 0;
			}

			@include media-breakpoint-up(xl) {
				padding: 0 45px 0 0;
				@include make-col(8);
				@include make-col-offset(2);
			}

			@include media-breakpoint-up(xxl) {
				@include make-col(8);
				@include make-col-offset(2);
			}
		}
	}

	&.contact {

		main {

			@include media-breakpoint-up(xl) {
				@include make-col(6);
				@include make-col-offset(2);
				padding: 0 0 0 50px;
			}
		}
	}

	aside {

		@include media-breakpoint-up(md) {
			@include make-col(3);
		}

		@include media-breakpoint-up(xl) {
			@include make-col(2);
			@include make-col-offset(1);
		}
	}

	&.list {

		aside {

			@include media-breakpoint-up(md) {
				margin-top: 73px;
			}

			@include media-breakpoint-up(xl) {
				@include make-col(2);
				@include make-col-offset(0);
			}
		}
	}

	&.contact {

		aside {

			@include media-breakpoint-up(xl) {
				@include make-col(4);
				@include make-col-offset(0);
				padding: 0 0 0 60px;
			}
		}
	}

	h1 {
		color: $primary;
	}

	// h1 + h2 {
	// 	font-weight: 400;
	// 	@include rem-calc(24px);
	// 	color: black;
	// 	margin-top: -11px;
	// }
}
