section.articleBody {
	padding-top: 50px;
	color: black;
	
	.container {

		@include media-breakpoint-up(lg) {
			display: flex;
		}
	}

	main {

		@include media-breakpoint-up(lg) {
			@include make-col(9);
			padding-right: 45px;
		}
	}

	aside {

		@include media-breakpoint-up(lg) {
			@include make-col(3);
		}

		@include media-breakpoint-up(xl) {
			@include make-col(2);
			@include make-col-offset(1);
		}

		h2 {
			@include rem-calc(26px);
			color: $secondary;
		}

		ul {
			font-weight: 700;
			@include rem-calc(16px);
		}
	}

	h1 {
		color: $primary;
		margin: 0 0 15px;
	}
}

.article-body--tags {
	@include rem-calc(14px);
	color: $primary;
	text-transform: uppercase;
	margin: 0 0 21px;
}

.article-body--meta {
	@include rem-calc(14px);
	color: #617A7D;
	margin: 0 0 50px;
}