.header-alert {
    font-size: .9rem;
    padding: 16px 0px;
    background-color: #fff;
    position: relative;

    @include media-breakpoint-up(md) {
    font-size: 1.05rem;
    }
}

.header-alert p {
    margin: auto;
    color: $secondary;
    font-weight: 500;
}

.header-alert a {
    min-width: 100%;
    color: $secondary;
    text-decoration: underline;
}

.header-alert .container {
    padding: 0px 16px;
}
