.article-list--wrapper {
	margin: 0 0 100px;
}

a.article-list--item {
	display: block;
	@include rem-calc(14px);
	color: $gray-dark;
	margin: 0 0 35px;
	position: relative;

	@include media-breakpoint-up(sm) {
		display: flex;
		align-items: center;
		margin: 0 0 25px;
	}

	&:hover,
	&:focus {
		text-decoration: none;
		background: $gray-lightest;
	}

	&:nth-child(6n+1) {
		
		@include media-breakpoint-up(md) {
			padding: 50px 0;
		}

		@include media-breakpoint-up(xl) {
			padding: 75px 0 35px;
		}

		h2 {

			@include media-breakpoint-up(md) {
				font-style: normal;
				font-weight: 700;
				margin: 0 0 21px;
			}

			@include media-breakpoint-up(xl) {
				@include rem-calc(60px);
			}
		}

		&:hover {

			.article-list--thumb {

				&::before {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: rgba(#000, 0.2);
				}
			}
		}

		.article-list--thumb {

			@include media-breakpoint-up(md) {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				min-width: 100%;
				max-width: 100%;
			}
		}

		.article-list--content {

			@include media-breakpoint-up(md) {
				display: flex;
				flex-direction: column;
				background: rgba(#fff, 0.9);
				padding: 25px;
				width: 100%;
			}

			@include media-breakpoint-up(lg) {
				padding: 35px;
			}

			p {
				@include media-breakpoint-up(md) {
					display: none;
				}
			}
		}

		.article-list--tags {
			color: #767676;
			
			@include media-breakpoint-up(md) {
				order: -1;
				margin: 0 0 21px;
			}
		}

		.article-list--credits {
			color: $primary;
			font-weight: 600;

			@include media-breakpoint-up(md) {
				margin: 0;
			}
		}

		.article-list--full-link {
			display: none;
		}
	}

	h2 {
		@include rem-calc(24px);
		color: $primary;
		margin: 0 0 11px;

		@include media-breakpoint-up(lg) {
			margin: 0 0 35px;
		}
	}

	.article-list--credits {
		@include rem-calc(12px);
		margin: 0 0 11px;
	}

	p {
		margin: 0 0 15px;
	}

	.article-list--tags {
		@include rem-calc(14px);
		text-transform: uppercase;
		color: $primary;
		font-weight: 600;
		margin: 0 0 15px;
	}

	.article-list--full-link {
		text-align: right;
		color: $secondary;
		display: block;
		text-transform: uppercase;
		font-weight: 700;

		&::after {
			content: "j";
			font-family: $icon-font;
			display: inline-block;
			margin: 0 0 0 3px;
			text-transform: none;
			vertical-align: middle;
		}
	}
}

.article-list--content {
	
	@include media-breakpoint-up(sm) {
		padding: 25px;
		position: relative;
	}
}

.article-list--thumb {
	display: none;

	@include media-breakpoint-up(sm) {
		display: block;
		max-width: 125px;
		min-width: 125px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50% 50%;
	}

	@include media-breakpoint-up(md) {
		min-width: 175px;
		max-width: 175px;
	}

	@include media-breakpoint-up(lg) {
		min-width: 300px;
		max-width: 300px;
	}

	&::after {
		content: "";
		display: block;
		padding: 0 0 90%;
	}
}
