section.routingIntro {
	text-align: center;
	@include rem-calc(20px);
	padding-bottom: 0;

	.container {
		max-width: 800px;
	}

	h1 {
		color: $primary;

		@include media-breakpoint-up(lg) {
			@include rem-calc(54px);
		}
	}
}
