.body-share {
	margin: 0 0 4rem;;

	h2 {
		@include rem-calc(20px);
		color: $secondary;
		text-transform: uppercase;

		@include media-breakpoint-up(lg) {
			@include rem-calc(24px);
		}
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-wrap: wrap;
	}

	li {
		margin: 0 11px 11px 0;
	}

	a {
		color: $primary;
		@include rem-calc(26px);

		&:hover {
			text-decoration: none;
			color: $secondary;
		}
	}
}
