section {
	padding: 24px 0;
	position: relative;

	@include media-breakpoint-up(lg) {
		padding: 32px 0;
	}

	@include media-breakpoint-up(xl) {
		padding: 32px 0;
	}
}

