@font-face {
    font-family: "asgct-icon";
    src: url("../fonts/global/asgct.eot");
    src: url("../fonts/global/asgct.eot?#iefix") format("embedded-opentype"),
        url("../fonts/global/asgct.woff") format("woff"),
        url("../fonts/global/asgct.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/global/MuseoSans_300-webfont.woff2') format('woff2'),
       url('../fonts/global/MuseoSans_300-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/global/MuseoSans_700-webfont.woff2') format('woff2'),
       url('../fonts/global/MuseoSans_700-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/global/MuseoSans_900-webfont.woff2') format('woff2'),
       url('../fonts/global/MuseoSans_900-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Chocolatier';
  src: url('../fonts/2025/Chocolatier.otf') format('opentype'),
       url('../fonts/2025/Chocolatier.woff2') format('woff2'),
       url('../fonts/2025/Chocolatier.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}



[data-icon]:before {
  font-family: "asgct-icon" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="bk-icon-"]:before,
[class*=" bk-icon-"]:before {
  font-family: "asgct-icon" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.socialLinks.footerSocial ul li a, .socialLinks.topSocial ul li a {
  padding: 4px;
}

.socialLinks.footerSocial ul li a i:before, .socialLinks.topSocial ul li a i:before {
  content: "";
  width: 16px;
  height: 16px;
  display: block;
  background-repeat: no-repeat;
  background-position: center center;
}

.socialLinks.topSocial {
  .bk-icon-facebook:before {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3e%3cpath fill='%23ffffff' d='M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z'/%3e%3c/svg%3e");
  }
  .bk-icon-twitter:before {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffffff' d='M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8l164.9-188.5L26.8 48h145.6l100.5 132.9L389.2 48zm-24.8 373.8h39.1L151.1 88h-42l255.3 333.8z'/%3E%3C/svg%3E");
  }
  .bk-icon-instagram:before {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3e%3cpath fill='%23ffffff' d='M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z'/%3e%3c/svg%3e");
  }
  .bk-icon-linkedin:before {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3e%3cpath fill='%23ffffff' d='M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 01107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z'/%3e%3c/svg%3e");
  }
  .bk-icon-youtube:before {
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4096 4096' fill='%23ffffff' %3E%3Ctitle%3E%3C/title%3E%3Cg id='icomoon-ignore'%3E%3C/g%3E%3Cpath d='M4055.2 1228.8c0 0-40-282.4-163.2-406.4-156-163.2-330.4-164-410.4-173.6-572.8-41.6-1432.8-41.6-1432.8-41.6h-1.6c0 0-860 0-1432.8 41.6-80 9.6-254.4 10.4-410.4 173.6-123.2 124-162.4 406.4-162.4 406.4s-40.8 331.2-40.8 663.2v310.4c0 331.2 40.8 663.2 40.8 663.2s40 282.4 162.4 406.4c156 163.2 360.8 157.6 452 175.2 328 31.2 1392.8 40.8 1392.8 40.8s860.8-1.6 1433.6-42.4c80-9.6 254.4-10.4 410.4-173.6 123.2-124 163.2-406.4 163.2-406.4s40.8-331.2 40.8-663.2v-310.4c-0.8-331.2-41.6-663.2-41.6-663.2zM1624.8 2579.2v-1151.2l1106.4 577.6-1106.4 573.6z'%3E%3C/path%3E%3C/svg%3E%0A");
  }
}

//Different FILL
.socialLinks.footerSocial {
  .bk-icon-facebook:before {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3e%3cpath fill='%231173ba' d='M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z'/%3e%3c/svg%3e");
  }
  .bk-icon-twitter:before {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%231173ba' d='M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8l164.9-188.5L26.8 48h145.6l100.5 132.9L389.2 48zm-24.8 373.8h39.1L151.1 88h-42l255.3 333.8z'/%3E%3C/svg%3E");
  }
  .bk-icon-instagram:before {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3e%3cpath fill='%231173ba' d='M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z'/%3e%3c/svg%3e");
  }
  .bk-icon-linkedin:before {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3e%3cpath fill='%231173ba' d='M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 01107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z'/%3e%3c/svg%3e");
  }
  .bk-icon-youtube:before {
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4096 4096' fill='%231173ba' %3E%3Ctitle%3E%3C/title%3E%3Cg id='icomoon-ignore'%3E%3C/g%3E%3Cpath d='M4055.2 1228.8c0 0-40-282.4-163.2-406.4-156-163.2-330.4-164-410.4-173.6-572.8-41.6-1432.8-41.6-1432.8-41.6h-1.6c0 0-860 0-1432.8 41.6-80 9.6-254.4 10.4-410.4 173.6-123.2 124-162.4 406.4-162.4 406.4s-40.8 331.2-40.8 663.2v310.4c0 331.2 40.8 663.2 40.8 663.2s40 282.4 162.4 406.4c156 163.2 360.8 157.6 452 175.2 328 31.2 1392.8 40.8 1392.8 40.8s860.8-1.6 1433.6-42.4c80-9.6 254.4-10.4 410.4-173.6 123.2-124 163.2-406.4 163.2-406.4s40.8-331.2 40.8-663.2v-310.4c-0.8-331.2-41.6-663.2-41.6-663.2zM1624.8 2579.2v-1151.2l1106.4 577.6-1106.4 573.6z'%3E%3C/path%3E%3C/svg%3E%0A");
  }
}



.bk-icon-material-design-iconic-font:before {
  content: "\65";
}
.bk-icon-material-design-iconic-font-1:before {
  content: "\66";
}
.bk-icon-material-design-iconic-font-2:before {
  content: "\67";
}
.bk-icon-material-design-iconic-font-3:before {
  content: "\68";
}
.bk-icon-material-design-iconic-font-4:before {
  content: "\69";
}
.bk-icon-material-design-iconic-font-5:before {
  content: "\6a";
}
.bk-icon-material-design-iconic-font-6:before {
  content: "\6b";
}
.bk-icon-material-design-iconic-font-7:before {
  content: "\6c";
}
.bk-icon-material-design-iconic-font-8:before {
  content: "\6d";
}
.bk-icon-angle-double-left:before {
  content: "\6e";
}
.bk-icon-angle-double-right:before {
  content: "\6f";
}
.bk-icon-material-design-iconic-font-9:before {
  content: "\70";
}
.bk-icon-material-design-iconic-font-10:before {
  content: "\71";
}
.bk-icon-material-design-iconic-font-11:before {
  content: "\72";
}
