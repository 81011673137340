.crumbs {
	color: $secondary;
	@include rem-calc(14px);
	padding: 30px 0 0;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: flex-start;
	}

	a {
		color: $secondary;
		text-decoration: none;
		display: inline-block;

		&::after {
			content: ">";
			display: inline-block;
			margin: 0 7px;
		}
	}
}