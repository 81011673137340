.faqContainer {

	@include media-breakpoint-up(md) {
		margin: 0 0 5rem;
	}
}

.faqBox {
	margin: 0 0 31px;
}

.faqQuestion {
	@include rem-calc(20px);
	color: $primary-accent;
	line-height: 1.2;
	cursor: pointer;
}

.faqAnswer {
	padding: 15px 0;
	display: none;
}

.faqToggle {
	border-bottom: 1px solid $gray-light;
	@include rem-calc(14px);
	font-weight: 700;
	text-transform: uppercase;
	color: $secondary;
	padding: 9px 0;
	text-align: right;
	cursor: pointer;

	&.on {

		&::after {
			content: "k";
		}
	}

	&::after {
		content: "h";
		text-transform: none;
		display: inline-block;
		margin: 0 0 0 3px;
		font-family: $icon-font;
		vertical-align: middle;
		font-weight: normal;
		@include rem-calc(26px);
	}
}
