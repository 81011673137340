html,
body {
	color: #000;
	font-size: 100%;
	font-family: $font-body;
	-webkit-font-smoothing: antialiased;
	margin: 0;
	padding: 0;
	height: 100%;

	&.stuck {
		overflow: hidden;
		position: fixed;
		width: 100%;
	}
}


p,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;

	&:last-child {
		margin-bottom: 0;
	}
}

p,
ul,
ol {
	margin-bottom: $master-margin-bottom;
}

p+h2,
p+h3,
p+h4,
p+h5,
ul+h2,
ul+h3,
ul+h4,
ol+h2,
ol+h3,
ol+h4,
ol+h5 {
	margin-top: 3rem;
}

img {
	max-width: 100%;
	height: auto;
}

ul,
ol {

	li {
		line-height: 1.2;
		margin-bottom: 0.8em;

		&:last-child {
			margin-bottom: 0;
		}

		ul,
		ol {
			margin-top: 0.8em;
		}
	}
}

a {
	color: $primary;
	text-decoration: underline;

	&:hover {
		color: $secondary;
		text-decoration: none;
	}

}

h1 {
	color: $primary;
	font-family: $font-headings;
	font-weight: normal;
	@include rem-calc(32px);

	@include media-breakpoint-up(md) {
		@include rem-calc(42px);
	}
}

h2 {
	font-family: $font-headings;
  font-feature-settings: "liga" 0;
	font-weight: 800;
	color: $secondary;
	// text-transform: uppercase;
	@include rem-calc(26px);

	@include media-breakpoint-up(md) {
		@include rem-calc(32px);
	}
}

h3 {
	font-family: $font-headings;
  font-feature-settings: "liga" 0;
	font-weight: 700;
	color: $primary;
	// text-transform: uppercase;
	@include rem-calc(24px);

	@include media-breakpoint-up(md) {
		@include rem-calc(28px);
	}
}

h4 {
	font-family: $font-headings;
  font-feature-settings: "liga" 0;
	font-weight: 700;
	color: $primary;
	// text-transform: uppercase;
	@include rem-calc(20px);

	@include media-breakpoint-up(md) {
		@include rem-calc(24px);
	}
}

h5 {
	font-family: $font-body;
	font-weight: 700;
	color: $primary;
	@include rem-calc(20px);
}

h6 {
	color: $primary;
	font-family: $font-body;
	font-weight: 700;
	@include rem-calc(18px);
}

table {
	max-width: 100%;
}

hr {
	border-top: solid 1px $gray-dark;
	margin-top: 2.5rem;
	margin-bottom: 2.5rem;
}

// Buttons
.btn {
	@include rem-calc(14px);
	border-width: 2px;
	display: inline-block;
	font-family: $font-body;
	font-weight: bold;
	line-height: 1.2rem;
	text-decoration: none;

	&.btn-primary {
		background: $secondary;
		border-color: $secondary;
		color: #fff;

		&:hover {
			background: $primary;
			border-color: $primary;
			color: #fff;
		}
	}

	&.btn-accent3 {
		background: $secondary;
		border-color: $secondary;
		color: #fff;

		&:hover {
			background: $primary;
			border-color: $primary;
			color: #fff;
		}
	}

	&.btn-accent3.ghost, // <-- would like to remove this approach (eventually), but found that its used in several places
	&.btn-outline {
		background: #fff;
		border-color: $primary;
		color: $primary;

		&:hover {
			border-color: $secondary;
			background: $secondary;
			color: #fff;
		}
	}

	&.btn-white {
		background: #fff;
		border-color: #fff;
		color: $primary;

		&:hover {
			border-color: $secondary;
			background: $secondary;
			color: #fff;
		}
	}
}


i.fa-check {
	color: $primary-accent;
}

.callout {
	border-left: 15px solid $secondary;
	font-size: 20px;
	font-weight: bold;
	color: black;
	padding: 11px 25px;
	line-height: 1.8;
	margin: 0 0 2rem;
}

.body-quote {
	border-left: 15px solid $secondary;
	padding: 11px 25px 40px;
	@include rem-calc(20px);
	color: $secondary;
	margin: 0 0 2rem;
}




.wide {
	width: 100%;
}
