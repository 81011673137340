section.cardTeaserSection {
	background: url(../images/global/texture-bg-1.jpg) 50% 50% no-repeat;
	background-size: cover;
	text-align: center;

	h2 {
		@include big-header;
	}

	.card-deck {
		flex-wrap: wrap;

		@include media-breakpoint-up(md) {
			flex-wrap: nowrap;
		}

		@include media-breakpoint-up(xl) {
			@include make-col(10);
			@include make-col-offset(1);
		}
	}

	.card {
		width: 100%;
		min-width: 100%;
		margin-left: 0 !important;
		margin-right: 0;

		@include media-breakpoint-up(md) {
			width: 31%;
			min-width: 31%;
			margin: 0;
		}
	}
}
