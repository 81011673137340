.asideBox {
	margin: 0 0 25px;
	border-bottom: 1px solid $gray-light;
	padding: 0 0 25px;

	&:last-child {
		border-bottom: 0;
	}

	@include media-breakpoint-up(md) {
		margin: 0 0 35px;
		padding: 0 0 35px;
	}

	&.asideContacts {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		@include media-breakpoint-up(md) {
			flex-wrap: wrap;
		}

		h4 {
			width: 100%;
		}
	}

	h4 {
		border-bottom: solid 1px $gray-light;
		line-height: 2rem;
	}

	ul {
		@include rem-calc(15px);
		margin: 0;
		padding: 0;
		list-style: none;

		& + h4 {
			margin-top: 3rem;
		}

		a, a:visited {
			color: $primary-accent;
			font-weight: 400;
			text-decoration: underline;
		}
		a:hover {
			text-decoration: none;
		}
	}

	li {
		margin: 0 0 15px;

		&.active {
			font-weight: 700;


			li {
				font-weight: 400;
				text-decoration: underline;
			}
		}
	}

	ul ul {
		@include rem-calc(13px);
		padding: 15px 0 0 15px;
	}
}

.asideBoxEditableRegion {
	margin-bottom: 48px;


	h4 {
		border-bottom: solid 1px $gray-light;
		line-height: 2rem;
	}

	p {
		color: $gray-dark;
	}
}
.asideContact {
    margin: 0 0 35px;
    width: calc(50% - 10px);

    @include media-breakpoint-up(sm) {
        width: calc(33.33333% - 10px);
    }

    @include media-breakpoint-up(md) {
        width: 100%;
    }

    &:last-child {
        margin: 0;
    }
}

.asideContactName {
	color: $secondary;
	@include rem-calc(20px);
	font-style: italic;
}

.asideContactTitle {
	color: $secondary;
	@include rem-calc(16px);
	margin: 0 0 11px;
}
