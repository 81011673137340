.masthead {
	background-color: #fff5ec;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 80px;
	padding: 0;
	position: relative;

	@include media-breakpoint-up(md) {
		min-height: 180px;
	}

	@include media-breakpoint-up(lg) {
		padding: 30px 0 15px 0;
	}

	@include media-breakpoint-up(xl) {
		padding: 75px 0 55px 0;
	}

	@include media-breakpoint-up(xxl) {
		padding: 100px 0 70px 0;
	}

	&::before,
	&::after {
		background-repeat: no-repeat;
		background-size: contain;
		position: absolute;
		top: -15px;

		@include media-breakpoint-up(md) {
			content: '';
			height: 150px;
			width: 150px;
		}

		@include media-breakpoint-up(lg) {
			height: 200px;
			width: 200px;
		}

		@include media-breakpoint-up(xl) {
			height: 300px;
			width: 300px;
		}

		@include media-breakpoint-up(xxl) {
		}
	}

	&::before {
		background-image: url('../images/2025/masthead-top-left.png');
		left: -5px;
	}

	&::after {
		background-image: url('../images/2025/masthead-top-right.png');
		right: -5px;
	}

	.container {
		align-items: center;
	  // background-blend-mode: multiply;
		// background-position: 50% 50%;
		// background-repeat: no-repeat;
		// background-size: cover;
		display: flex;
		justify-content: center;
		max-width: none;

	  @include media-breakpoint-up(lg) {
	  	// background-size: 100%;
	  }
	}

	.mastheadCaption {
		color: $primary;
		text-align: center;
		padding: 24px 16px 32px 24px;

		@include media-breakpoint-up(sm) {
			bottom: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			padding: 32px 16px 42px 24px;
		}
	}

	.mastheadHeader {
		@include rem-calc(50px);
		font-family: $font-headings;
		color: $primary;
		font-weight: normal;
		line-height: .9;
		text-transform: uppercase;
		margin-bottom: 0;

		@include media-breakpoint-up(md) {
			@include rem-calc(70px);
			padding: 0 4rem;
		}

		@include media-breakpoint-up(lg) {
			@include rem-calc(90px);
			padding: 0 6rem;
		}
	}

	.mastheadSub {
		@include rem-calc(30px);
		font-weight: 900;
		line-height: 1.2;
		margin-bottom: 0;
		margin-top: .5rem;
		text-transform: uppercase;

		@include media-breakpoint-up(md) {
			@include rem-calc(35px);
		}
	}

	a.btn {
		@include rem-calc(16px);
		padding: 8px 24px;
		margin: 16px 8px 0 8px;
		background-color: $primary;
		border-color: $primary;
		color: #fff;

		&:hover {
			background: $secondary;
			border-color: $secondary;
			color: #fff;
		}

		@include media-breakpoint-up(md) {
			padding: 12px 24px;
		}
	}
}


// ========================================================
// Homepage Masthead
.masthead-home {
}


// ========================================================
// General Masthead
.masthead-hub {
	.masthead {
		@include media-breakpoint-up(md) {
			min-height: 180px;
		}

		@include media-breakpoint-up(lg) {
			padding: 30px 0;
		}

		@include media-breakpoint-up(xl) {
			padding: 60px 0;
		}

		@include media-breakpoint-up(xxl) {
			padding: 100px 0;
		}
	}

	.mastheadSub {
		@include rem-calc(24px);

		@include media-breakpoint-up(md) {
			@include rem-calc(32px);
		}
	}
}
