.alertPanel {
	color: #fff;
	text-align: center;
	padding: 25px 0;

	@include media-breakpoint-up(md) {
		padding: 40px 0;
	}

	@include media-breakpoint-up(xl) {
		padding: 60px 0;
	}

	.container {

		@include media-breakpoint-up(sm) {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
		}

		@include media-breakpoint-up(md) {

		}
	}
}

.alertPanelYear {
	font-weight: 700;
	@include rem-calc(50px);
	line-height: 1.2;

	@include media-breakpoint-up(sm) {
		padding: 0 25px 0 0;
		border-right: 5px solid $secondary;
		margin: 0 0 0.6rem;
	}

	@include media-breakpoint-up(md) {
		margin: 0;
	}
}

.alertText {

	@include media-breakpoint-up(sm) {
		text-align: left;
		padding: 0 0 0 25px;
		margin: 0 0 0.6rem;
	}

	@include media-breakpoint-up(md) {
		margin: 0;
	}
}

.alertTextHeader {
	font-weight: 700;
	text-transform: uppercase;
	@include rem-calc(20px);
}

.alertTextSubHeader {
	@include rem-calc(18px);
	margin: 0 0 1rem;

	@include media-breakpoint-up(sm) {
		margin: 0;
	}
}

.alertAction {
	width: 100%;

	@include media-breakpoint-up(md) {
		padding: 0 25px;
		width: auto;
	}
}

.alertPanelClose {
	position: absolute;
	right: 11px;
	top: 0;
	color: #96452D;
	font-weight: 600;
	text-transform: uppercase;
	@include rem-calc(12px);
	cursor: pointer;

	@include media-breakpoint-up(sm) {
		top: -15px;
	}

	@include media-breakpoint-up(xl) {
		top: -35px;
		right: 8%;
	}

	&:hover {
		color: #fff;
	}

	&::after {
		content: "g";
		text-transform: none;
		font-family: $icon-font;
		display: inline-block;
		margin: 0 0 0 7px;
		vertical-align: middle;
		@include rem-calc(16px);
	}
}
