.logon-page-background {
    @include rem-calc(14px);
    color: #2F2E7B;

    input[type=checkbox] {

        & + label {
            padding-left: 5px;
        }
    }

    input[type=submit], createAccount {
        @include rem-calc(12px);
        padding-top: 7px;
        padding-bottom: 7px;
    }
}