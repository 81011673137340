.newsList {

	h2 + & {
		margin-top: 2rem;
	}

	.card-deck {
		display: flex;
		flex-wrap: wrap;
	}

	.card {
		width: 100%;
		min-width: 100%;
		margin: 0 0 41px !important;
		border-left: 15px solid $primary-accent;
	}
}

.listResultCount {
	@include rem-calc(18px);
	line-height: 1.2;
	margin: 0 0 25px;

	span {
		font-weight: 700;
		color: $secondary;
	}
}

.detailMeta {
	color: $primary;
	font-weight: 700;
	line-height: 1.2;
	margin: 0 0 15px;

	@include media-breakpoint-up(md) {
		margin: 0 0 45px;
	}
}
