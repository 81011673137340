@mixin rem-calc($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

@mixin big-header {
   @extend h2;

	@include media-breakpoint-up(md) {
		@include rem-calc(36px);
		margin: 0 0 2rem;
	}

	@include media-breakpoint-up(lg) {
		margin: 0 0 2.6rem;
	}
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}
