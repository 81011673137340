section.filterSection {

	h1 {

		@include media-breakpoint-up(md) {
			margin: 0 0 45px;
		}
	}
}

.filters {

	@include media-breakpoint-up(md) {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
	}
}

.filterContainer {

	@include media-breakpoint-up(xl) {
		@include make-col(8);
		@include make-col-offset(2);
	}

	&.left {

		@include media-breakpoint-up(lg) {
			margin: 0 0 50px;
		}
		
		@include media-breakpoint-up(xl) {
			@include make-col(12);
			@include make-col-offset(0);
		}
	}

	.lead {
		font-weight: 700;
		@include rem-calc(20px);
		margin: 0 0 21px;

		@include media-breakpoint-up(md) {
			@include rem-calc(24px);
		}

		&::before {
			content: "e";
			display: inline-block;
			font-family: $icon-font;
			margin: 0 7px 2px 0;
			vertical-align: middle;
		}
	}

	input[type="text"] {
		background: url(../images/global/icon-search.png) calc(100% - 15px) 50% no-repeat;
		appearance: none;
		border-radius: 0;
		border: 1px solid $secondary;
		height: 40px;
		line-height: 40px;
		padding: 0 15px;
		@include rem-calc(16px);
		width: 100%;
		margin: 0 0 1rem;

		@include media-breakpoint-up(md) {
			width: 200px;
			flex-grow: 1;
			margin: 0;
			height: auto;
		}
	}

	.dropDownWrapper {

		@include media-breakpoint-up(md) {
			margin: 0 0 0 9px;
		}
	}

	input[type="submit"] {
		margin: 0 0 0 9px;
	}
}
