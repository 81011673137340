.bodyQuote {
	border-left: 15px solid $primary-accent;
	padding: 35px 25px;
	margin: 0 0 2rem;
	@include rem-calc(20px);
	line-height: 1.8;

	@include media-breakpoint-up(md) {
		margin: 0 0 5rem;
		padding: 35px 0 35px 50px;
	}
}

.bodyQuoteCredit {
	@include rem-calc(16px);
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;

	&::before {
		content: "-";
		display: inline-block;
		margin: 0 9px 0 0;
	}
}

.bodyQuoteContent {

	@include media-breakpoint-up(md) {
		padding: 0 0 0 35px;
	}
}

.bodyQuoteOpen {
	position: relative;
	height: 35px;
	overflow: hidden;
	margin: 0 0 31px;

	span {
		height: 25px;
		width: 65px;
		position: absolute;
		top: 0;
		left: 0;
		background: url(../images/global/openQuote.png) 0 50% no-repeat;
		background-size: auto 100%;

		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			left: 100%;
			right: -9999px;
			border-top: 1px solid $gray-light;
		}
	}
}

.bodyQuoteClose {
	position: relative;
	height: 35px;
	overflow: hidden;
	margin: 31px 0 0;

	span {
		height: 25px;
		width: 65px;
		position: absolute;
		top: 0;
		right: 0;
		background: url(../images/global/closeQuote.png) 100% 50% no-repeat;
		background-size: auto 100%;

		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			right: 100%;
			left: -9999px;
			border-top: 1px solid $gray-light;
		}
	}
}
