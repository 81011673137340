.event-row {
	background: $gray-base;
	margin: 0 0 50px;
	color: #fff;
	text-align: center;
	padding: 25px;
	box-shadow: 0 0 9px rgba(#000, 0.2);

	@include media-breakpoint-up(md) {
		display: flex;
		align-items: center;
		text-align: left;
		justify-content: center;
	}

	@include media-breakpoint-up(lg) {
		margin: 0 0 150px;
		padding: 40px 0;
	}

	.year {
		@include rem-calc(40px);
		font-weight: 700;
		position: relative;
		line-height: 1;
		padding: 0 0 15px;
		margin: 0 0 15px;

		@include media-breakpoint-up(md) {
			padding: 0 15px 0 0;
			margin: 0 15px 0 0;
		}

		@include media-breakpoint-up(lg) {
			@include rem-calc(80px);
			padding: 0 35px 0 0;
			margin: 0 35px 0 0;
		}

		@include media-breakpoint-up(xl) {
			padding: 0 50px 0 0;
		}

		&::after {
			content: "";
			display: block;
			position: absolute;
			bottom: 0;
			left: 50%;
			width: 100px;
			border-bottom: 2px solid #fff;
			transform: translate(-50%, 0);

			@include media-breakpoint-up(md) {
				bottom: auto;
				top: 50%;
				left: auto;
				right: 0;
				transform: translate(0, -50%);
				width: auto;
				height: 65px;
				border: none;
				border-right: 3px solid #fff;
			}
		}
	}

	.content {
		@include rem-calc(20px);
		margin: 0 0 25px;

		@include media-breakpoint-up(md) {
			margin: 0 15px 0 0;
		}

		@include media-breakpoint-up(lg) {
			margin: 0 35px 0 0;
		}

		@include media-breakpoint-up(xl) {
			margin: 0 50px 0 0;
		}

		h2 {
			color: #fff;
			@include rem-calc(30px);

			@include media-breakpoint-up(md) {
				margin: 0 0 3px;
			}
		}
	}

	.action {

		.btn {
			color: $secondary;
			@include rem-calc(13px);

			&.btn-white {
				color: #fff;

				&:hover {
					color: $secondary;
				}
			}
		}
	}
}
