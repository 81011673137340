section.meetingSection {
	background: url(../images/global/meeting-section-bg.jpg) 50% 50% no-repeat;
	background-size: cover;
	text-align: center;

	@include media-breakpoint-up(xl) {
		padding: 110px 0;
	}
}

.meetingSectionIntro {
	max-width: 700px;
	margin: 0 auto 2rem;

	@include media-breakpoint-up(lg) {
		@include rem-calc(20px);
	}
}

.meetingBoxContainer {

	@include media-breakpoint-up(md) {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	@include media-breakpoint-up(xl) {
		@include make-col(10);
		@include make-col-offset(1);
	}
}

.meetingFeature {
	border-left: 15px solid $secondary;
	background: #fff;
	box-shadow: $box-shadow;
	padding: 0 25px 25px;
	margin: 0 0 1rem;

	@include media-breakpoint-up(md) {
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding: 0;
	}
}

.meetingFeatureThumb {
	margin: 0 -25px 1rem;

	@include media-breakpoint-up(md) {
		min-width: 40%;
		max-width: 40%;
		margin: 0;
	}

	&::after {
		content: "";
		display: block;
		padding: 0 0 60%;
	}
}

.meetingFeatureLead,
.meetingBoxLead {
	color: $primary;
	font-weight: 700;
	text-transform: uppercase;
	@include rem-calc(12px);
	margin: 0 0 15px;

	@include media-breakpoint-up(lg) {
		@include rem-calc(14px);
	}
}

.meetingFeatureContent {

	@include media-breakpoint-up(md) {
		text-align: left;
		align-self: center;
		padding: 40px;
	}

	@include media-breakpoint-up(lg) {
		padding: 48px;
	}

	h2 a {
		color: $primary;
		@include rem-calc(32px);
	}

	p {
		color: black;
	}
}

.meetingFeatureMeta {

	a, a:visited {

		&::after {
			content: ",";
			display: inline-block;
			margin: 0 4px 0 0;
		}
	}
}

/*.meetingFeatureAction {

	@include media-breakpoint-up(md) {
		text-align: center;
	}
}*/

.meetingBox {
	box-shadow: $box-shadow;
	background: #fff;
	padding: 25px;
	margin: 0 0 1rem;

	@include media-breakpoint-up(lg) {
		width: 49%;
		padding-bottom: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		padding: 30px 30px 0;
		flex-direction: column;
		margin: 0;
	}

	.meetingBoxLead {
		width: 100%;

		@include media-breakpoint-up(sm) {
			text-align: left;
		}

		@include media-breakpoint-up(lg) {
			flex-shrink: 1;
		}
	}

	a.btn {

		@include media-breakpoint-up(lg) {
			transform: translate(0, 50%);
		}
	}
}

.meetingBoxContentRow {

	@include media-breakpoint-up(sm) {
		display: flex;
		flex-wrap: nowrap;
		align-items: flex-start;
		justify-content: space-between;
		margin: 0 0 2rem;
		text-align: left;
	}

	@include media-breakpoint-up(lg) {
		margin: 0;
		flex-grow: 1;
	}
}

.meetingBoxThumb {
	width: 40%;
	max-width: 125px;
	margin: 0 auto 1rem;
	min-width: 125px;

	@include media-breakpoint-up(sm) {
		margin: 0;
	}

	&::after {
		content: "";
		display: block;
		padding: 0 0 120%;
	}
}

.meetingBoxContent {
	margin: 0 0 1rem;

	@include media-breakpoint-up(sm) {
		padding: 0 0 0 25px;
	}

	h2 {
		color: $secondary;
		@include rem-calc(20px);

		@include media-breakpoint-up(md) {
			@include rem-calc(24px);
		}
	}

	h3 {
		@include rem-calc(16px);

		h2 + & {
			margin-top: -1rem;
		}
	}
}
